import React from "react";
import { SocialIcon } from "react-social-icons";

const MySocialIcon = ({network, className, wrapperClassName, disabled, inactive, bgColor, style}) => {

    const socialIconNetwork =
        network == "twitter" 
            ? "x"
            : network;

    return (
        <div className={`relative ${disabled && `opacity-60`} ${inactive && `grayscale`} ${wrapperClassName}`} style={style}>
            <div className="absolute rounded-full w-[90%] h-[85%] left-[4.5%] top-[10%] bg-[#F6F6F6]"></div>
            <SocialIcon network={socialIconNetwork} className={className} bgColor={bgColor ? bgColor : undefined} />
        </div>
    )
}

export default MySocialIcon;