import React, { useContext, useState } from 'react';
import { Button, Select } from 'components/ui';
import { CompanyContext } from 'contexts/CompanyContext';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import { Trans } from 'react-i18next';
import Api from 'services/Api';
import { CalendarActiveSocialNetworkContext } from 'contexts/CalendarActiveSocialNetworkContext';

const countryOptions = [
    // América
    { value: 'US', label: '🇺🇸 United States' },
    { value: 'AR', label: '🇦🇷 Argentina' },
    { value: 'BR', label: '🇧🇷 Brazil' },
    { value: 'CA', label: '🇨🇦 Canada' },
    { value: 'CL', label: '🇨🇱 Chile' },
    { value: 'CO', label: '🇨🇴 Colombia' },
    { value: 'MX', label: '🇲🇽 Mexico' },
    { value: 'PE', label: '🇵🇪 Peru' },
    
    // Europa
    { value: 'DE', label: '🇩🇪 Germany' },
    { value: 'ES', label: '🇪🇸 Spain' },
    { value: 'FR', label: '🇫🇷 France' },
    { value: 'GB', label: '🇬🇧 United Kingdom' },
    { value: 'IE', label: '🇮🇪 Ireland' },
    { value: 'IT', label: '🇮🇹 Italy' },
    { value: 'NL', label: '🇳🇱 Netherlands' },
    { value: 'PL', label: '🇵🇱 Poland' },
    { value: 'PT', label: '🇵🇹 Portugal' },
    { value: 'RU', label: '🇷🇺 Russia' },
    { value: 'SE', label: '🇸🇪 Sweden' },
    { value: 'TR', label: '🇹🇷 Turkey' },
    
    // Asia y Pacífico
    { value: 'AU', label: '🇦🇺 Australia' },
    { value: 'HK', label: '🇭🇰 Hong Kong' },
    { value: 'ID', label: '🇮🇩 Indonesia' },
    { value: 'IN', label: '🇮🇳 India' },
    { value: 'JP', label: '🇯🇵 Japan' },
    { value: 'KR', label: '🇰🇷 South Korea' },
    { value: 'MY', label: '🇲🇾 Malaysia' },
    { value: 'NZ', label: '🇳🇿 New Zealand' },
    { value: 'PH', label: '🇵🇭 Philippines' },
    { value: 'SG', label: '🇸🇬 Singapore' },
    { value: 'TH', label: '🇹🇭 Thailand' },
    { value: 'TW', label: '🇹🇼 Taiwan' },
    { value: 'VN', label: '🇻🇳 Vietnam' },
    
    // Medio Oriente y África
    { value: 'AE', label: '🇦🇪 United Arab Emirates' },
    { value: 'EG', label: '🇪🇬 Egypt' },
    { value: 'IL', label: '🇮🇱 Israel' },
    { value: 'SA', label: '🇸🇦 Saudi Arabia' },
    { value: 'ZA', label: '🇿🇦 South Africa' },

    // Otros países relevantes
    { value: 'CH', label: '🇨🇭 Switzerland' },
    { value: 'CZ', label: '🇨🇿 Czech Republic' },
    { value: 'DK', label: '🇩🇰 Denmark' },
    { value: 'FI', label: '🇫🇮 Finland' },
    { value: 'NO', label: '🇳🇴 Norway' },
    { value: 'RO', label: '🇷🇴 Romania' },
    { value: 'UA', label: '🇺🇦 Ukraine' },
];

const YoutubeRegionSelectModal = () => {

    const { setYoutubeRegionSelectModalOpened } = useContext(CalendarActiveSocialNetworkContext);
    const { company, updateSocials, companySocials } = useContext(CompanyContext);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRegion, setSelectedRegion] = useState('US');
    const youtubeSocialNetwork = companySocials?.filter(e => e.type == 'youtube')?.[0];

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            await Api.put(`/socialNetworks/${youtubeSocialNetwork?.id}`, {}, {
                preferences: {
                    region_code: selectedRegion
                }
            });
            
            await updateSocials();
            
            setYoutubeRegionSelectModalOpened(false);
            
            localStorage.removeItem("social_network_updated");
            
        } catch (error) {
            console.error('Error updating YouTube region:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="relative">
            <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm" onClick={() => setYoutubeRegionSelectModalOpened(false)} />
            
            {/* Modal */}
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl max-w-md w-full mx-4">
                    {/* Header */}
                    <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
                        <h4 className="flex items-center gap-2 text-lg font-semibold text-gray-900 dark:text-gray-100">
                            <img src="/img/socials/youtube.png" className="w-6" />
                            <Trans i18nKey="youtubeIntegration.selectRegionTitle">
                                Select YouTube Region
                            </Trans>
                        </h4>
                    </div>

                    <div className="p-6">
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
                                <label className="text-gray-700 dark:text-gray-200 font-normal text-sm">
                                    <Trans i18nKey="youtubeIntegration.selectRegion">
                                        Select your content region
                                    </Trans>
                                </label>
                                <Select
                                    options={countryOptions}
                                    value={countryOptions.find(option => option.value === selectedRegion)}
                                    onChange={(option) => setSelectedRegion(option.value)}
                                    className="w-full text-sm text-gray-900 dark:text-gray-100"
                                    menuPlacement="auto"
                                />
                                <span className="text-gray-500 dark:text-gray-400 text-xs mt-4">
                                    <Trans i18nKey="youtubeIntegration.regionHelp">
                                        This selection helps us customize your experience by displaying YouTube categories and trending content specific to your region. For example, if you select 'United States', you'll see trending topics and content categories that are popular in the US market
                                    </Trans>
                                </span>
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="flex justify-end gap-2 p-4 border-t border-gray-200 dark:border-gray-700">
                        <Button onClick={() => setYoutubeRegionSelectModalOpened(false)}>
                            <Trans i18nKey="common.cancel">Cancel</Trans>
                        </Button>
                        <Button
                            variant="solid"
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            <Trans i18nKey="common.save">Save</Trans>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default YoutubeRegionSelectModal; 