import EmojiPicker from "emoji-picker-react";
import React from "react";

import useDarkMode from "utils/hooks/useDarkMode";



const AddEmojis = ({ value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isDark ] = useDarkMode()

    const handleOnClick = (emoji) => {
        setValue((prev) => (prev ? prev?.substring(0, selectionStartEnd.finish) : "") + emoji + (prev ? prev?.substring(selectionStartEnd.finish, prev?.length) : ""));
    }

    return (
        <div className="flex items-center justify-center p-0 gap-2">
            <EmojiPicker 
                onEmojiClick={(obj) => {
                    handleOnClick(obj.emoji)
                }}
                autoFocusSearch={true}
                theme={isDark ? "dark" : "light"}
                emojiStyle="native"
                width={"100%"}
            />
        </div>
    )
}

export default AddEmojis;