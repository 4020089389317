import React, { useContext, useEffect } from "react";
import { CompanyContext } from 'contexts/CompanyContext'
import { postTypesOptions } from "utils/hooks/useConfigPanel";
import usePrompts from 'utils/hooks/usePrompts';

const getDefaultPrompts = (prompts = [])  => {
    return prompts?.filter(e => !!e.default);
}

const CompanyPromptsContext = React.createContext({
    prompts: [],
    defaultPrompts: [],
    getRandomPrompt: () => void 0,
    refreshPrompts: () => void 0,
    createPrompt: () => void 0,
    updatePrompt: () => void 0,
    deletePrompt: () => void 0
});

const CompanyPromptsProvider = ({ children }) => {
    const { company } = useContext(CompanyContext);
    const { 
        prompts: allPrompts, 
        fetchFollowrPrompts, 
        fetchCompanyPrompts,
        createPrompt: createPromptHook,
        updatePrompt: updatePromptHook,
        deletePrompt: deletePromptHook
    } = usePrompts();
    
    const refreshPrompts = async () => {
        await Promise.all([
            fetchFollowrPrompts(),
            fetchCompanyPrompts()
        ]);
    };

    const createPrompt = async (network, name = null, prompt = null) => {
        await createPromptHook(network, name, prompt);
        await refreshPrompts();
    };

    const updatePrompt = async (promptId, promptData, refresh = true) => {
        await updatePromptHook(promptId, promptData);
        if (refresh) {
            await refreshPrompts();
        }
    };

    const deletePrompt = async (prompt) => {
        await deletePromptHook(prompt);
        await refreshPrompts();
    };

    useEffect(() => {
        if (company?.id) {
            fetchFollowrPrompts();
            fetchCompanyPrompts();

            postTypesOptions.forEach(async (option) => {
                const network = option.value;
                const hasNetworkPrompts = allPrompts?.some(p => p.social_network_type === network);
                
                // TODO: no entinedo esto
                // if (!hasNetworkPrompts) {
                //     await createPrompt(network, firstPrompt);
                // }
            });
        }
    }, [company?.id]);

    const getRandomPrompt = (network) => {
        const networkPrompts = allPrompts.filter(p => p.social_network_type === network);
        const companyPrompts = networkPrompts.filter(p => p.company_id !== null);
        
        if (companyPrompts.length > 0) {
            return companyPrompts[Math.floor(Math.random() * companyPrompts.length)];
        }

        const followrPrompts = networkPrompts.filter(p => p.company_id === null);
        
        if (followrPrompts.length > 0) {
            return followrPrompts[Math.floor(Math.random() * followrPrompts.length)];
        }
        
        return "";
    }

    return (
        <CompanyPromptsContext.Provider value={{
            prompts: allPrompts,
            defaultPrompts: getDefaultPrompts(allPrompts),
            getRandomPrompt,
            refreshPrompts,
            createPrompt,
            updatePrompt,
            deletePrompt
        }}>
            {children}
        </CompanyPromptsContext.Provider>
    )
}

export { CompanyPromptsContext, CompanyPromptsProvider };