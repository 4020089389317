import { Loading } from "components/shared";
import { AIContext } from "contexts/AIContext";
import React, { useContext, useEffect, useState } from "react";

const ImproveConciseness = ({ value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    let chunkLength = 0;

    useEffect(() => {
        setIsLoading(true);
        const query = `
            Improve the conciseness of this text: ${selectedText ? selectedText : fullText}\n
            If it can't be improved, then answer the same text.

            Conciseness improved text: 
        `;
        getByQueryChat(query).then((response) => {
            const initialLeftPart = selectedText ? String(value)?.substring(0, selectionStartEnd.start) : "";
            const initialRightPart = selectedText ? String(value)?.substring(selectionStartEnd.finish, String(value)?.length) : "";       
            setValue(`${initialLeftPart}${response}${initialRightPart}`);
        }).finally(() => {
            setIsLoading(false);
            setMenuOpened(false);
        })
    }, []);

    return (
        <div className="flex items-center justify-center p-4 gap-2">
            <Loading type={'element'} /> 
        </div>
    )
}

export default ImproveConciseness;