import { Loading } from "components/shared";
import { AIContext } from "contexts/AIContext";
import React, { useContext, useEffect,  useState } from "react";




const AddQuote = ({ inputRef, value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    let chunkLength = 0;

    useEffect(() => {
        const query = `
            Write a real quote ${fullText && `for the following text: ${selectedText ? selectedText : fullText}`}
            Real quote:    
        `;
        getByQueryChat(query).then((response) => {
            const initialLeftPart = String(value)?.substring(0, selectionStartEnd.start);
            const initialRightPart = String(value)?.substring(selectionStartEnd.finish, String(value)?.length);    
            setValue(`${initialLeftPart}${response}${initialRightPart}`);
        }).finally(() => {
            setIsLoading(false);
            setMenuOpened(false);
        })
    }, []);

    return (
        <div className="flex flex-col items-center justify-center gap-2">
            <Loading type={'element'} /> 
        </div>
    )
}

export default AddQuote;