import { Loading } from "components/shared";
import { Button } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import { AIContext } from "contexts/AIContext";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineUser } from "react-icons/hi2";

const ChangeAudience = ({ audience, value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    const [ customAudience, setCustomAudience ] = useState("");
    let chunkLength = 0;

    const handleChangeAudience = () => {
        setIsLoading(true);
        const query = `
            Write this text for ${customAudience ? customAudience : audience}: ${selectedText ? selectedText : fullText}
            Text: 
        `;
        getByQueryChat(query).then((response) => {
            const initialLeftPart = selectedText ? String(value)?.substring(0, selectionStartEnd.start) : "";
            const initialRightPart = selectedText ? String(value)?.substring(selectionStartEnd.finish, String(value)?.length) : "";    
            setValue(`${initialLeftPart}${response}${initialRightPart}`);
        }).finally(() => {
            setIsLoading(false);
            setMenuOpened(false);
        })
    }

    const handleOnClick = () => {
        handleChangeAudience();
    }

    useEffect(() => {
        if (audience) {
            handleChangeAudience();
        }
    }, []);

    return (
        <div className="flex items-center justify-center p-0 gap-2">
            {isLoading 
                ? <Loading type={'element'} /> 
                : 
                <div className="flex flex-col gap-2">
                    <InputModern 
                        autoFocus
                        value={customAudience}
                        onChange={(e) => setCustomAudience(e.target.value)}
                        placeholder={`Try "Small Business Owners"`}
                    />
                    <div className="items-center w-full">
                        <Button loading={isLoading} disabled={isLoading} onClick={handleOnClick} className="w-full" variant="solid" icon={<HiOutlineUser />}>&nbsp; Change Audience</Button>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default ChangeAudience;