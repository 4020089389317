import React, { useContext, useState } from 'react'
import { Button, Checkbox, Select, Tooltip } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import { API_HOST } from 'services/Api';
import { Trans } from 'react-i18next';


const ThreadsIntegrationModal = () => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { company } = useContext(CompanyContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleSubmit = () => {
        window.open(`${API_HOST}/companies/${company?.id}/socialNetworks/threads?token=${localStorage.getItem('token')}`);
        setIsLoading(true);
    }

    return (
        <>

            <img src={`/img/others/threads-modal.png`} className='max-w-none !w-[150%] top-0 left-[-25%] top-0 absolute' />
            <div className='flex flex-col gap-7 pt-4 mt-24'>
                <img src={`/img/socials/threads.png`} className='h-12 mx-auto dark:invert mb-4' />

                <p className='text-center !text-sm'>Connecting your Threads account is quick and easy. Simply click the "Connect with Threads" button below, and you'll be redirected to authorize the connection between your Threads profile and Followr.</p>

                <p className='text-left !text-sm'>
                    <b className='block text-center'><Trans i18nKey={`threadsIntegration.permissions`}>Followr will request the following permissions</Trans>: <span onClick={() => setIsCollapsed(!isCollapsed)} className='text-primary-600 cursor-pointer'>{isCollapsed ? `View` : `Hide`} permissions</span></b>
                    <div className={`overflow-hidden transition-all rounded-2xl border border-gray-200 dark:border-gray-600 p-4 px-8 !text-sm bg-gray-50 dark:bg-gray-800 mt-4 ${isCollapsed && `hidden !h-0`}`}>
                        <ul className={`!list-disc`}>
                            <li><b>threads_basic</b>: View your Threads account information and profile data.</li>
                            <li><b>threads_content_publish</b>: Create and manage posts on your Threads account.</li>
                        </ul>
                    </div>
                </p>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button onClick={closeModalPriority}>
                    <Trans i18nKey={`threadsIntegration.cancel`}>Cancel</Trans>
                </Button>
                <Tooltip
                    title={"Please accept the privacy policy first"}
                    wrapperClass="inline-block"
                >
                    <Button 
                        loading={isLoading} 
                        variant="solid" 
                        onClick={handleSubmit} 
                    >
                        <Trans i18nKey={`threadsIntegration.connectWith`}>Connect with Threads</Trans>
                    </Button>
                </Tooltip>
            </div>
        </>
    )
}

export default ThreadsIntegrationModal
