import { Loading } from "components/shared";
import { Button } from "components/ui";
import InputModern from "components/ui/Input/InputModern";
import React, { useEffect, useState } from "react";
import { HiOutlineLink } from "react-icons/hi2";




const AddLink = ({ link, value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ customLink, setCustomLink ] = useState("");
    
    const handleAddLink = () => {
        setValue((prev) => (prev ? prev?.substring(0, selectionStartEnd.finish) : "") + (customLink ? customLink : link) + (prev ? prev?.substring(selectionStartEnd.finish, prev?.length) : ""));
        setMenuOpened(false);
    }

    const handleOnClick = () => {
        handleAddLink();
    }

    useEffect(() => {
        if (link) {
            handleAddLink();
        } else {
            setCustomLink("https://")
        }
    }, []);

    return (
        <div className="flex items-center justify-center p-0 gap-2">
            {isLoading 
                ? <Loading type={'element'} /> 
                : 
                <div className="flex flex-col gap-2 w-full">
                    <InputModern 
                        autoFocus
                        value={customLink}
                        onChange={(e) => setCustomLink(e.target.value)}
                        placeholder={`Try "https://followr.ai"`}
                    />
                    <div className="items-center w-full">
                        <Button loading={isLoading} disabled={isLoading} onClick={handleOnClick} className="w-full" variant="solid" icon={<HiOutlineLink />}>&nbsp; Add Link</Button>
                    </div>
                </div>
            }
            
        </div>
    )
}

export default AddLink;