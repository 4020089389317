import React, { useContext, useEffect, useState } from 'react'
import { Badge, Button, Dropdown, Notification, Progress, Radio, Switcher, toast } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { HiOutlineChevronDown, HiOutlineChip, HiOutlineLightningBolt, HiOutlinePlusCircle } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/formatNumber';
import { BsCheckCircleFill, BsCircle, BsLayers } from 'react-icons/bs';
import { MdLayers } from 'react-icons/md';
import { HiChevronRight, HiOutlineCheck, HiOutlineSquare2Stack, HiOutlineSquare3Stack3D, HiOutlineUser, HiOutlineXMark, HiPlus, HiRocketLaunch } from 'react-icons/hi2';
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import Api from 'services/Api';
import { SessionContext } from 'contexts/SessionContext';
import SwitcherModern from 'components/ui/SwitcherModern';
import CardDialog from 'views/subscription/CardDialog';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Loading } from 'components/shared';
import { Trans } from 'react-i18next';
import { FREE_TRIAL_DAYS } from 'components/ui/utils/constant';

const SelectPlanModal = ({ limit = true }) => {

    const { closeModalPriority } = useContext(PriorityModalContext);
    const { user, updateUserData } = useContext(SessionContext);

    const [productActive, setProductActive] = useState();
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [recurring, setRecurring] = useState(user?.subscription?.items?.[0]?.price?.recurring);

    const [ cardDialogOpened, setCardDialogOpened ] = useState(false);
    const [ isSubmittingCard, setIsSubmittingCard ] = useState(false);
    const [ subscriptionCreated, setSubscriptionCreated ] = useState(false);
    
    const stripe = useStripe();
    const elements = useElements();

    const actualPlanId = user?.subscription?.items?.filter(e => e.product.type == "plan")?.[0]?.id;

    const discountText = process.env.REACT_APP_DEFAULT_DISCOUNT;
    const discountFactor = parseFloat(discountText) ? 1 - parseFloat(discountText) / 100 : 1;

    const updateSubscription = () => {
        let newProductPriceId = products?.filter(e => e.id == productActive)?.[0]?.prices?.filter(e => e.recurring == recurring)?.[0]?.id;
        let newSubscription = user?.subscription?.items?.filter(e => e.product.type != "plan")?.map((item) => {
            return {
                "id": item.price.id,
                "quantity": item.quantity
            }
        });
        newSubscription = [{
            "id": newProductPriceId,
            "quantity": 1
        }].concat(newSubscription);

        newSubscription = newSubscription.filter(e => e != null);
        
        if (user?.subscription) {
            setIsSubmitting(true);
            Api.put(`/subscriptions`, {}, { prices: newSubscription }).then((response) => {
                toast.push(<Notification title="Great, plan has been upgraded! You can keep inviting!" type="success" />, { placement: "top-center" });
                updateUserData();
                setIsSubmitting(false);
                closeModalPriority();
            }).catch((err) => {
                toast.push(<Notification title={err.message} type="danger" />, { placement: "top-center" });
                closeModalPriority();
            }).finally(() => {
                setIsSubmitting(false);
            });
        } else {
            setCardDialogOpened(true);
        }
    }

    const handleSubmitStripe = async (event) => {
        event.preventDefault();
        if (elements == null) {
            return;
        }
        
        setIsSubmittingCard(true);
        
        const planObj = (products.filter(item => item.id == productActive))[0];
        const planPriceObj = (planObj.prices.filter(price => price.recurring == recurring))[0];

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });

        if (error) {
            setIsSubmittingCard(false);
            toast.push(<Notification title={error.message} type="warning" />, { placement: 'top-center' });
            return;
        }

        let data = {
            payment_method_id: paymentMethod.id,
            prices: [ { "id": planPriceObj.id } ]
        };
        
        Api.post(`/subscriptions`, {}, data)
            .then((response) => {
                setSubscriptionCreated(true);
                updateUserData();
            })
            .catch((err) => {
                toast.push(<Notification title={err.message} type="danger" />, { placement: 'top-center' });
            })
            .finally(() => {
                setIsSubmittingCard(false);
            });        
    }

    useEffect(() => {
        setIsLoading(true);
        Api.get(`/products?include=prices`).then(({ data: _products }) => {
            const filteredProducts = _products.filter(item => item.type == "plan").sort((a, b) => a?.prices?.[0].amount - b?.prices?.[0].amount);
            setProducts(filteredProducts);
            if (!user?.subscription) {
                setProductActive(filteredProducts?.[0]?.id);
            } else {
                setProductActive(user?.subscription?.items?.filter(e => e.product.type == "plan")?.[0]?.id);
            }
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (user && user.subscription) {
            setRecurring(user?.subscription?.items?.[0]?.price?.recurring);
        } else {
            setRecurring('year');
        }
    }, [user]);

    return (
        <>
            <div className='flex flex-col gap-2 pt-4'>
                {limit 
                    ? <>
                        <h3 className='text-gray-900 mega-title'>Select a subscription plan</h3>
                        <h6 className='text-gray-700 font-normal'>Whether you are a big agency or a professional freelancer, we have a plan for you!</h6>
                    </>
                    : <>
                        <h3 className='text-gray-900 mega-title'>Add Users</h3>
                    </>
                }

                {!user?.subscription && 
                    <div className='mt-4'>
                        <div className='flex flex-col gap-4'>
                            <div className='flex items-center justify-start gap-2'>
                                <div className='flex items-center gap-2'>
                                    <span>Monthly</span> 
                                </div>
                                <Switcher defaultChecked onChange={(val) => setRecurring(val == true ? 'year' : 'month')} />
                                <div className='flex items-center gap-2'>
                                    <span>Annualy</span> 
                                    <Button variant="solid" className='font-bold rounded-lg !p-1 !px-2.5 inline-block !h-auto !text-xs flex gap-1.5 items-center bg-primary w-auto'>
                                        <HiRocketLaunch />
                                        <span>Save 35%</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className='flex flex-col gap-2 pt-6'>
                    {isLoading 
                        ? <Loading type={`element`} />
                        : <>
                            {products && products.map((item, key) => (
                                <div onClick={() => setProductActive(item?.id)} key={key} className={`relative cursor-pointer flex justify-between items-center gap-4 rounded-2xl border border-gray-200 dark:border-gray-600 border-solid py-4 px-4 transition-all hover:bg-primary-600/5 ${productActive == item?.id ? `!bg-primary-600/20 border-2 !border-primary-600 dark:!border-primary-600` : ``}`}>
                                    <div className='flex items-center gap-6'>
                                        <div>
                                            <img src={`/img/others/super-plan-${key}.png`} className="w-20 h-20 object-contain mx-auto z-10 relative" />
                                        </div>
                                        <div className='flex flex-col gap-0 text-gray-800 dark:text-gray-100 w-[150px]'>
                                            {user?.subscription && user?.subscription?.items?.filter(e => e.product.id == item.id)?.length > 0 && <span className='bg-gradient-primary text-white font-bold px-3 py-1 rounded-xl w-fit !text-xs'>Actual Plan</span>}
                                            <div className='font-bold !text-lg mega-title'>Plan <span className='capitalize'>{item.name}</span></div>
                                            <div className='!text-xs !-mt-0'>${formatNumber(item?.prices?.filter(e => e.recurring == recurring)?.[0]?.amount / (recurring == "year" ? 12 : 1) / 100, 0)}/month</div>
                                        </div>
                                        <div className='flex flex-col gap-1.5'>
                                            <div className='flex items-center gap-2'>
                                                <HiOutlineCheck className='text-primary-600 text-xl'/> 
                                                <span className='group-[.is-active]:!text-gray-50'>{item?.users_amount} User{item?.users_amount > 1 && 's'}</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <HiOutlineCheck className='text-primary-600 text-xl'/> 
                                                <span className='group-[.is-active]:!text-gray-50'>{item?.companies_amount} Compan{item?.users_amount > 1 ? 'ies' : 'y'}</span>
                                            </div>
                                            <div className='flex items-center gap-2'>
                                                <HiOutlineCheck className='text-primary-600 text-xl'/> 
                                                <span className='group-[.is-active]:!text-gray-50'>{formatNumber(item?.words_amount)} AI-Generated Words</span>
                                            </div>
                                            {!!item?.getlead_enabled && <div className='flex items-center gap-2'>
                                                {!!item?.getlead_enabled 
                                                    ? <HiOutlineCheck className='text-primary-600 text-xl'/> 
                                                    : <HiOutlineXMark className='text-red-600 text-xl'/> 
                                                }
                                                <span className='group-[.is-active]:!text-gray-50 flex items-center gap-2'><Trans i18nKey={`subscription.aiChatbros`}>AI-Chatbot <div className="inline-flex items-center gap-1 font-semibold text-purple-800"><img src="https://chatbros.ai/images/logo.png" className="h-4" /> Chatbros</div></Trans></span>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-0 items-right'>
                                        {discountFactor < 1 && 
                                            <div className="flex items-center gap-2">
                                                <Badge content={`${discountText}% OFF`} />
                                                <h1 className='!text-sm group-[.is-active]:!text-white text-right line-through opacity-[0.4]'>
                                                    ${formatNumber(((item?.prices?.filter(e => e.recurring == recurring)[0]).amount) / (recurring == 'year' ? 12 : 1) / 100 , 2)} 
                                                </h1>
                                            </div>
                                        }
                                        <h1 className='mega-title !text-xl group-[.is-active]:!text-white text-right'>
                                            ${formatNumber(((item?.prices?.filter(e => e.recurring == recurring)[0]).amount) / (recurring == 'year' ? 12 : 1) / 100 * discountFactor, 2)} 
                                            &nbsp;<small>USD</small>
                                        </h1>
                                        <h4 className='text-gray-300 font-semibold mt-0 !text-sm text-right'>/ month</h4>
                                    </div>
                                    <div className='text-xl'>
                                        {productActive == item?.id
                                            ? <BsCheckCircleFill className='text-primary-600' />
                                            : <BsCircle className='text-gray-200 dark:text-gray-600' />
                                        }
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                </div>


                <div className='flex justify-between gap-2 pt-5'>
                    <p className='dark:text-white text-left font-normal'>
                        {!user?.subscription && !!FREE_TRIAL_DAYS && <>You won't be charged until {FREE_TRIAL_DAYS} days after subscribing.<br />You can unsubscribe at any time.</>}
                    </p>
                    <Button loading={isSubmitting} disabled={!productActive || actualPlanId == productActive} variant="solid" onClick={updateSubscription} className="flex items-center gap-4">
                        <span>Select Plan</span>
                        <HiChevronRight />
                    </Button>
                </div>
            </div>
            
            <CardDialog 
                className='z-[10000000000001]'
                cardDialogOpened={cardDialogOpened} 
                setCardDialogOpened={setCardDialogOpened} 
                handleSubmitStripe={handleSubmitStripe} 
                isSubmittingCard={isSubmittingCard}
                subscriptionCreated={subscriptionCreated}
            />
        </>
    )
}

export default SelectPlanModal
