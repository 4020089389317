import React, { useContext, useState } from 'react'
import { Button, Checkbox, Select, Tooltip } from 'components/ui'
import { CompanyContext } from 'contexts/CompanyContext'
import { PriorityModalContext } from 'contexts/PriorityModalContext';
import { API_HOST } from 'services/Api';
import { Trans } from 'react-i18next';


const YoutubeIntegrationModal = () => {
    const { closeModalPriority } = useContext(PriorityModalContext);
    const { company } = useContext(CompanyContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [acceptedPolicies, setAcceptedPolicies] = useState(false);

    const handleSubmit = () => {
        window.open(`${API_HOST}/companies/${company?.id}/socialNetworks/youtube?token=${localStorage.getItem('token')}`);
        setIsLoading(true);
    }

    return (
        <>
            <div className='flex flex-col gap-7 pt-4'>
                <img src={`/img/socials-3d/youtube_light.png`} className='h-12 mx-auto dark:hidden mb-4' />
                <img src={`/img/socials-3d/youtube_dark.png`} className='h-12 mx-auto hidden dark:inline-block mb-4' />

                <p className='text-left !text-smm'>
                    <b className='block text-center'><Trans i18nKey={`youtubeIntegration.permissions`}>Followr will request the following permissions</Trans>: <span onClick={() => setIsCollapsed(!isCollapsed)} className='text-primary-600 cursor-pointer'>{isCollapsed ? `View` : `Hide`} permissions</span></b>
                    <div className={`overflow-hidden transition-all rounded-2xl border border-gray-200 dark:border-gray-600 p-4 px-8 !text-sm bg-gray-50 dark:bg-gray-800 mt-4 ${isCollapsed && `hidden !h-0`}`}>
                        <ul className={`!list-disc`}>
                            <li><b>youtube.readonly</b>: View your YouTube account information and channel data.</li>
                            <li><b>youtube.upload</b>: Manage your YouTube videos and upload new content.</li>
                            <li><b>youtube.force-ssl</b>: Secure access to YouTube data via HTTPS.</li>
                            <li><b>youtubepartner</b>: View and manage your YouTube content and channel settings.</li>
                            <li><b>youtube.channel-memberships.creator</b>: Manage your channel membership offerings.</li>
                            <li><b>youtube</b>: Manage your YouTube account including uploads and analytics.</li>
                            <li><b>youtube.analytics.readonly</b>: View YouTube Analytics data for your content.</li>
                            <li><b>youtube.readonly</b>: View basic YouTube account information.</li>
                        </ul>
                    </div>
                </p>
                <p className='text-center !text-sm'>
                    <Trans i18nKey={`youtubeIntegration.requirements`}>
                        To connect your YouTube account, make sure you have:
                    </Trans>
                </p>
                <div className='flex items-center justify-center w-full '>
                    <ul className='text-sm list-disc pl-8 -mt-4'>
                        <li><Trans i18nKey={`youtubeIntegration.requirement1`}>A YouTube account with a verified channel</Trans></li>
                        <li><Trans i18nKey={`youtubeIntegration.requirement2`}>Channel features enabled for content uploading</Trans></li>
                        <li><Trans i18nKey={`youtubeIntegration.requirement3`}>Accepted YouTube's Terms of Service</Trans></li>
                    </ul>
                </div>
                <p className='text-center !text-sm'>
                    <Trans i18nKey={`youtubeIntegration.forMoreInfo`}>
                        For more information about YouTube channel requirements
                    </Trans> 
                    {" "}
                    <a href="https://help.followr.ai" target="_blank" className="text-primary-600">
                        <Trans i18nKey={`youtubeIntegration.clickHere`}>click here</Trans>
                    </a>.
                </p>

                <div className="flex items-start justify-center gap-0 mt-4">
                    <Checkbox
                        id="privacyPolicyCheckbox"
                        className="mt-1"
                        checked={acceptedPolicies}
                        onChange={(checked) => setAcceptedPolicies(checked)}
                    />
                    <label htmlFor="privacyPolicyCheckbox" className="text-sm inline-block mt-1">
                        <Trans i18nKey="youtubeIntegration.acceptPrivacyPolicy">
                            I accept the{' '}
                            <a 
                                href="https://followr.ai/privacy-policy" 
                                target="_blank" 
                                className="text-primary-600 hover:underline"
                            >
                                Followr Privacy Policy
                            </a>
                            {' '}and{' '}
                            <a 
                                href="https://policies.google.com/privacy" 
                                target="_blank" 
                                className="text-primary-600 hover:underline"
                            >
                                Google Privacy Policy
                            </a>
                        </Trans>
                    </label>
                </div>
            </div>

            <div className='flex justify-end gap-2 p-4 -m-6 w-auto mt-10 border-t border-gray-200 dark:border-gray-700'>
                <Button onClick={closeModalPriority}>
                    <Trans i18nKey={`youtubeIntegration.cancel`}>Cancel</Trans>
                </Button>
                <Tooltip
                    title={"Please accept the privacy policy first"}
                    wrapperClass="inline-block"
                >
                    <Button 
                        loading={isLoading} 
                        variant="solid" 
                        onClick={handleSubmit} 
                        disabled={!acceptedPolicies}
                    >
                        &nbsp; <Trans i18nKey={`youtubeIntegration.connectWith`}>Connect with YouTube</Trans>
                    </Button>
                </Tooltip>
            </div>
        </>
    )
}

export default YoutubeIntegrationModal
