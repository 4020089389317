import { Loading } from "components/shared";
import { AIContext } from "contexts/AIContext";
import React, { useContext, useEffect, useState } from "react";


const ContinueWriting = ({ value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    let chunkLength = 0;

    useEffect(() => {
        setIsLoading(true);
        const query = `
            Continue writing the given text.
            Do not include the given text in your answer.
            Write 200 characters aprox.

            Given text: ${selectedText ? selectedText : fullText.substring(0, selectionStartEnd.finish)}
        `;
        getByQueryChat(query).then((response) => {
            const initialLeftPart = String(value)?.substring(0, selectionStartEnd.start);
            const initialRightPart = String(value)?.substring(selectionStartEnd.finish, String(value)?.length);    
            setValue(`${initialLeftPart}${response}${initialRightPart}`);
        }).finally(() => {
            setIsLoading(false);
            setMenuOpened(false);
        })
    }, []);

    return (
        <div className="flex items-center justify-center p-4 gap-2">
            <Loading type={'element'} /> 
        </div>
    )
}

export default ContinueWriting;