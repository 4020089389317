import { Loading } from "components/shared";
import { Button } from "components/ui";
import { CompanyContext } from "contexts/CompanyContext";
import { AIContext } from "contexts/AIContext";
import { TrendingNewsContext } from "contexts/TrendingNewsContext";
import React, { useContext, useEffect, useState } from "react";


const BrainstormIdeas = ({ value, fullText, selectedText, selectionStartEnd, setValue, setMenuOpened }) => {

    const [ isLoading, setIsLoading ] = useState(false);
    const { getByQueryChat } = useContext(AIContext);
    const { trendingNews } = useContext(TrendingNewsContext);
    const { company } = useContext(CompanyContext);
    const [ ideas, setIdeas ] = useState("");
    const perPage = 15;

    const handleOnClick = async (about) => {
        setIsLoading(true);

        const query = `
            ${fullText && `The text you need to complete is: ${selectedText.trim() ? selectedText : fullText.substring(0, selectionStartEnd.finish)}`}
            Write 280 characters about "${about}".\n\n\n
            Do not include the given text. 
        `;
        getByQueryChat(query).then((response) => {
            const initialLeftPart = String(value)?.substring(0, selectionStartEnd.start);
            const initialRightPart = String(value)?.substring(selectionStartEnd.finish, String(value)?.length);    
            setValue(`${initialLeftPart}${response}${initialRightPart}`);
        }).finally(() => {
            setIsLoading(false);
            setMenuOpened(false);
        })
    }

    const generateIdeas = () => {
        setIsLoading(true);

        const query = `
            ${fullText?.trim() 
                ? `Brainstorm ${perPage} 3-word ideas to write about given the following text: "${selectedText.trim() ? selectedText : fullText.substring(0, selectionStartEnd.finish)}"`
                : `Brainstorm ${perPage} 3-word ideas to write a social post for ${company?.name}`
            }\n\n\n
            Separate each idea using a new line. 
            Do not answer in list format. 
            Do not use quotation marks.
            Do not numarate every option. 
        `;
        getByQueryChat(query).then((response) => {
            setIdeas((prev) => prev + response);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        generateIdeas();
    }, []);

    return (
        <div className="flex items-center justify-center p-0 gap-2 max-w-[350px]">
            {isLoading 
                ? <Loading type={'element'} /> 
                : <div>
                    <div className="max-h-[350px] overflow-y-auto">
                        {ideas && ideas.split("\n")?.filter(e => e?.length > 5 && e.split(" ")?.length < 15)?.map(e => e?.includes(". ") ? e.split(". ")?.[1] : e)?.slice(0, -1)?.map((item, key) => (
                            <div 
                                key={key} 
                                className="m-1 cursor-pointer inline-block px-2.5 py-1 rounded-xl border border-gray-200 dark:border-gray-600 !text-[10px] transition-all hover:bg-primary-600/20 hover:border-primary-600 hover:text-primary-600"
                                onClick={() => handleOnClick(item)}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                    <Button onClick={generateIdeas} variant="twoTone" className="w-full mt-2 text-xs" size="sm">Brainstorm more...</Button>
                </div>
            }
        </div>
    )
}

export default BrainstormIdeas;