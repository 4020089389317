import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ModalContext = React.createContext({
    isOpened: false,
    openModal: (component, width = undefined, hasCloseButton = true, isClosable = true, contentClassName = "") => void 0,
    closeModal: () => void 0,
    component: <></>,
    dialogWidth: undefined,
    hasCloseButton: true,
    isClosable: true,
    contentClassName: ""
});

const ModalProvider = ({ children }) => {
    
    const [isOpened, setIsOpened] = useState(false);
    const [component, setComponent] = useState(<></>);
    const [dialogWidth, setDialogWidth] = useState();
    const [hasCloseButton, setHasCloseButton] = useState(true);
    const [isClosable, setIsClosable] = useState(true);
    const [contentClassName, setContentClassName] = useState("");

    const openModal = (component, dialogWidth = undefined, hasCloseButton = true, isClosable = true, contentClassName = "") => {
        setContentClassName(contentClassName);
        setComponent(component);
        setIsOpened(true);
        setDialogWidth(dialogWidth);
        setHasCloseButton(hasCloseButton);
        setIsClosable(isClosable);
    }

    const closeModal = () => {
        setComponent(<></>);
        setIsOpened(false);
    }

    return (
        <ModalContext.Provider value={{ 
            isOpened: isOpened, 
            openModal: openModal, 
            closeModal: closeModal, 
            component: component, 
            dialogWidth: dialogWidth,
            hasCloseButton: hasCloseButton,
            isClosable: isClosable,
            contentClassName: contentClassName   
        }}>
            {children}
        </ModalContext.Provider>
    );
};

export { ModalContext, ModalProvider };