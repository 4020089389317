import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import cookieImg from 'assets/images/other/cookie.png';
import { COOKIE_DOMAIN } from 'components/ui/utils/constant';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    // Check if user has already consented
    const hasConsented = cookies.get('cookie_consent');
    if (hasConsented === undefined) {
      setTimeout(() => {
        setIsVisible(true);
      }, 4000);
    }
  }, []);

  const handleAccept = () => {
    // Set cookie consent for 1 year
    cookies.set('cookie_consent', true, {
      path: '/',
      domain: COOKIE_DOMAIN.includes("localhost") ? undefined : COOKIE_DOMAIN || "followr.ai",
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
      secure: true,
      sameSite: 'strict'
    });
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 md:bottom-4 border border-gray-50 dark:border-transparent right-0 md:right-4 md:max-w-md bg-white dark:bg-gray-900 rounded-t-xl md:rounded-xl shadow-xl p-6 z-50">
      <div className="flex flex-col items-center text-center gap-4">
        <img src={cookieImg} alt="Cookie" className="w-16 h-16" />
        
        <h3 className="text-xl font-bold">
          Cookie Notice
        </h3>
        
        <p className="text-sm text-gray-600 dark:text-gray-300">
          We use cookies to enhance your experience and analyze our website traffic. By continuing to use our site, you agree to our <a href="https://followr.ai/cookie-policy" target="_blank" className="text-primary-600 hover:text-primary-700">cookie policy</a>.
        </p>

        <div className="w-full mt-2">
          <button
            onClick={handleAccept}
            className="w-full px-4 font-semibold py-2 text-white bg-primary-600 hover:bg-primary-700 rounded-lg transition-colors"
          >
            Understood
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
